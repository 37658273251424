import classNames from "classnames";
import Link from "next/link";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { paths } from "@paths";
import { FeaturedProducts } from "@utils/ssr";

import { Button, ProductsFeatured } from "../../components";
import { structuredData } from "../../core/SEO/Homepage/structuredData";
import noPhotoImg from "../../images/no-photo.svg";
import {
  HomePageProducts_categories,
  HomePageProducts_shop,
} from "./gqlTypes/HomePageProducts";

import "./scss/index.scss";

const Page: React.FC<{
  categories: HomePageProducts_categories;
  featuredProducts: FeaturedProducts;
  shop: HomePageProducts_shop;
}> = ({ categories, featuredProducts, shop }) => {
  const categoriesExist = () => {
    return categories && categories.edges && categories.edges.length > 0;
  };
  const intl = useIntl();

  return (
    <>
      <script className="structured-data-list" type="application/ld+json">
        {structuredData(shop)}
      </script>
      <div className="container hero">
        <div className="home-page__hero-grid">
          <div className="home-page__hero-header">
            <div className="home-page__hero-text">
              <div>
                <span className="home-page__hero__title">
                  <h1>
                    <FormattedMessage defaultMessage="For The Real Masters" />
                  </h1>
                </span>
              </div>
              <div>
                <span className="home-page__hero__title">
                  <p>
                    <FormattedMessage defaultMessage="Welcome to PETS online store." />
                  </p>
                  <p>
                    <FormattedMessage defaultMessage="At PETS, we are passionate about providing you and your pets with the best care and best, genuine products." />
                  </p>
                  <p>
                    <FormattedMessage defaultMessage="We are loving pet parents ourselves and are out to make other pet parents’ lives easier, any way we can." />
                  </p>
                </span>
              </div>
            </div>
            <div className="home-page__hero-action">
              {categoriesExist() && (
                <Link
                  href={generatePath(paths.category, {
                    slug: categories.edges[0].node.slug,
                  })}
                >
                  <a>
                    <Button testingContext="homepageHeroActionButton">
                      <FormattedMessage defaultMessage="Shop sale" />
                    </Button>
                  </a>
                </Link>
              )}
            </div>
          </div>
          <div className="home-page__hero-images" />
          <div className="home-page__hero-images" />
        </div>
      </div>
      <ProductsFeatured
        products={featuredProducts.products}
        title={intl.formatMessage({ defaultMessage: "Featured products" })}
      />
      {categoriesExist() && (
        <div className="home-page__categories">
          <div className="container">
            <div className="home-page__categories-wrapper">
              <h3>
                <FormattedMessage defaultMessage="Shop by category" />
              </h3>
              <div className="home-page__categories__list">
                {categories.edges
                  .sort(
                    (a, b) =>
                      parseInt(a.node.metadata[0].value, 10) -
                      parseInt(b.node.metadata[0].value, 10)
                  )
                  .map(({ node: category }) => (
                    <div key={category.id}>
                      <Link
                        href={generatePath(paths.category, {
                          slug: category.slug,
                        })}
                        key={category.id}
                      >
                        <a>
                          <div
                            className={classNames(
                              "home-page__categories__list__image",
                              {
                                "home-page__categories__list__image--no-photo": !category.backgroundImage,
                              }
                            )}
                            style={{
                              backgroundImage: `url(${
                                category.backgroundImage
                                  ? category.backgroundImage.url
                                  : noPhotoImg
                              })`,
                            }}
                          />
                          <h3>{category.name}</h3>
                        </a>
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Page;
